import React from 'react'
import { Container, Card, CardBody } from 'reactstrap'
import Improvements from './Improvements'
import Intro from './Intro'
import QuestionAnswers from './QuestionAnswers'
import ScenarioCompare from './ScenarioCompare'
import ScenarioRelations from './ScenarioRelations'
import { useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getFeedback } from '../../api/api'
import actions from '../../state/actions'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

const NotEnoughRespondents = () => {
  const { t, i18n } = useTranslation()

  return (
  <Container className='pt-5'>
    <h1>{t('FEEDBACK')}</h1>
    <p className='lead'>
    {t('NOT_ENOUGH_SURVEYS_LEAD')}
    </p>
    <p>
      <Trans i18nKey='NOT_ENOUGH_SURVEYS_BODY' t={t} >
      Probeer het later nog eens door deze pagina te vernieuwen. Via <a href={window.location.href}>deze link</a> kun je op een 
      later moment alsnog je feedback ophalen.
      </Trans>
    </p>
  </Container>
  )

}

const View04Feedback = () => {
  const { t, i18n } = useTranslation()

  const params = useParams()
  const dispatch = useDispatch()
  const respondent = useSelector(state => state.v04Feedback.respondent)
  const scenarios = useSelector(state => state.v04Feedback.scenarios)
  const status = useSelector(state => state.v04Feedback.status)
  const lang = useSelector(state => state.language)
  const questionsAndAnswers = useSelector(state => state.v04Feedback.questions_and_answers)
  
  useEffect(() => {
    getFeedback(params.access_code).then( data =>
      {
        dispatch(actions.setV04Feedback(data))
      }
    )
  }, [params.access_code, lang])
  return (
    <div className='mb-5'>
      { respondent && status === 'OK' && <Container className='pt-5'>
        <h1>{t('WELCOME!')}</h1>
        <p className='lead'><b>{t('FEEDBACK_LEAD')}</b></p>
        <div className='mb-5' /> 
        <hr />
        <Intro /> 
        <div className='mb-5' /> 
        <hr />
        <ScenarioCompare scenarios={scenarios} />  
        <div className='mb-5' /> 
        <hr />
        <ScenarioRelations />  
        <div className='pb-5' /> 
        <hr />
        <Improvements scenarios={scenarios} />  
        <div className='pb-5' /> 
        <hr />
        <QuestionAnswers questionsAndAnswers={questionsAndAnswers}/>  
        <div className='pb-5' /> 
      </Container> }
      {status === 'NOT_ENOUGH_RESPONDENTS' && <NotEnoughRespondents/>}
    </div>
  )
}

export default View04Feedback
