import React from 'react'
import styled from 'styled-components'

const OuterContainer = styled.div`
  height: 18px;
  display: block;
  position: relative;
`

const BackLinePast = styled.div`
  height: 5px;
  background-color:  #189DD9;
  width: ${p => p.width + '%'}
`
const BackLineAhead = styled.div`
  height: 5px;
  background-color: #dadada;
  width: ${p => p.width + '%'}
`
const BallFull = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 30px;
  border: solid 2px #189DD9;
  background-color: #189DD9;
`

const BallSemiFull = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 30px;
  border: solid 2px #dadada;
  background-color: white;
`
const BackLineContainer = styled.div`
  top: 6.75px;
  z-index: -9999;
  left: 0;
  width: 100%;
  position: absolute;
  display: flex;
  
`
const ProgressBalls = ({ steps = 0, currentStep = 0, className }) => {
  const balls = []

  for (let i = 0; i < steps; i++) {
    if (i < currentStep) {
      balls.push(<BallFull key={i} />)
    } else {
      balls.push(<BallSemiFull key={i} />)
    }
  }

  const stepSize = 100 / (steps - 1)
  const pastWidth = (currentStep - 1) * stepSize
  const aheadWidth = 100 - pastWidth  

  return (
    <div className={className}>
      {steps > 1 && <OuterContainer>
        <div style={{position: 'absolute', width: '100%'}}>
          <BackLineContainer>
            <BackLinePast width={pastWidth} />
            <BackLineAhead width={aheadWidth} />
          </BackLineContainer>
        </div>
        <div style={{position: 'absolute', width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          {balls}
        </div>        
      </OuterContainer>
      
      }
    </div>
  )
}

export default ProgressBalls
