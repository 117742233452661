import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Input, Label, ListGroup, ListGroupItem, FormGroup, Button } from 'reactstrap'
import actions from '../state/actions'

const Debug = () => {
  const skippableQuestions = useSelector(state => state.debug.skippableQuestions)
  const dispatch = useDispatch()
  const history = useHistory()

  function handleSkipScenarioQuestions () {
    dispatch(actions.setDebugSkippableScenarioQuestions(!skippableQuestions))
  }

  function removePersistent() {
    dispatch(actions.setPurgeStore())
    const splitted = window.location.pathname.split('/')
    if (splitted[2]) {
      history.push(`/enquete/${splitted[2]}`)
    } else {
      history.push('/')
    }    
  }
  
  return (
    <div>
      <FormGroup check>
        <Label check>
          <Input type='checkbox' onChange={handleSkipScenarioQuestions} checked={skippableQuestions} />{' '}
          Schakel verplichte scenariovragen uit
        </Label>
      </FormGroup>
      <hr/>
      
      <FormGroup check>
        <Button onClick={removePersistent} color='link' size='sm'>Verwijder persistent</Button>
      </FormGroup>
    </div>
  )
}

export default Debug
