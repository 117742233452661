import React, { useRef, useState } from 'react'
import logo from '../scss/assets/logo.svg'
import { useSelector, useDispatch } from 'react-redux'
import actions from '../state/actions'
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody, Popover } from 'reactstrap'
import Debug from '../elements/Debug'
import LanguageSwitch from '../components/LanguageSwitch'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const TopBar = () => {
  const dispatch = useDispatch()
  const debugBtn = useRef()
  const location = useLocation()

  const subTitle = useSelector(state => state.general?.subTitle)
  const debugMode = useSelector(state => state.debug?.enabled)
  const title = useSelector(state => state.uigeneral?.title)
  const [showDebugPopup, setShowDebugPopup] = useState(false)
  const [showLanguageSwitch, setShowLanguageSwitch] = useState(true)
  const [clickCnt, setClickCnt] = useState(0)

  useEffect(() => {
    const splitted = location.pathname.split('/')
    const isWelcomePage = splitted[3] === 'stap' && splitted[4] === '0'
    setShowLanguageSwitch(!isWelcomePage)
  }, [location.pathname])

  function handleClick(e) {
    if (e.ctrlKey && e.shiftKey) {
      setClickCnt(clickCnt + 1)
    }

    if (clickCnt === 3) {
      setClickCnt(0)
      dispatch(actions.setDebugMode(!debugMode))
    }
  }

  useEffect(() => {
    if (!debugMode) {
      setShowDebugPopup(false)
    }

  }, [debugMode])

  return (
    <div style={{ height: '53px' }} className='d-flex pl-3 align-items-center bg-primary sticky-top'>
      <img onClick={handleClick} style={{ height: '34px' }} className='mr-2' src={logo} alt='Logo' />
      <span className='text-light'><b>{title}{subTitle ? ` - ${subTitle}` : ''}</b></span>
      <div className='ml-auto mr-2 py-2 px-2'>
      { showLanguageSwitch && <LanguageSwitch />}
      </div>
      {debugMode &&
        <div className='ml-auto mr-2 py-2 px-2'>
        
          <Button onClick={() => setShowDebugPopup(!showDebugPopup)} innerRef={debugBtn} color='warning'>Debug</Button>
          {debugBtn.current && <Popover isOpen={debugMode && showDebugPopup} placement="bottom" target={debugBtn.current}>
          <PopoverHeader>Debug menu</PopoverHeader>
          <PopoverBody>
            <Debug />
          </PopoverBody>
        </Popover>}
        </div>
      }
      

    </div>
  )
}

export default TopBar
