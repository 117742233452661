import React from 'react'
import scenariosNl from '../../scss/assets/scenarios_nl.svg'
import scenariosEn from '../../scss/assets/scenarios_en.svg'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const scenarios = {
  'nl': scenariosNl,
  'en': scenariosEn
}

const ScenarioRelations = () => {
  const { t, i18n } = useTranslation()
  const activeLang = useSelector(state => state.language)
  const activeScenarios = scenarios[activeLang]

  return (
    <div>
      <h3 className='mb-5'>{t('IMPORTANT_SCENARIOS_TITLE')}</h3>
      <img className='mt-2' style={{maxWidth: '100%'}} src={activeScenarios} alt='Scenario-uitleg' />
    </div>
  )
}

export default ScenarioRelations
