import React from 'react'
import { Row, Col } from 'reactstrap'
import { Radar } from 'react-chartjs-2'
import { getRandomInt } from '../../utils/random'
import { Trans, useTranslation } from 'react-i18next'

const options = {
  scale: {
      ticks: {
          beginAtZero: true,
          max: 100
      }
  },
  plugins: {
    datalabels: {
        display: false
    }
}
}

const ScenarioCompare = ({ scenarios }) => {
  const { t, i18n } = useTranslation()

  const labels = scenarios.map(s => s.name)

  const dataLeft = {
    labels: labels,
    datasets: [
      {
        label: t('YOUR_ANSWERS'),
        backgroundColor: 'rgba(24, 157, 217, 0.2)',
        borderColor: 'rgba(24, 157, 217, 1)',
        pointBackgroundColor: 'rgba(24, 157, 217, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(24, 157, 217, 1)',
        data: scenarios.map(s => s.respondent.work_now_points)
      },
      {
        label: t('YOUR_COLLEAGUES'),
        backgroundColor: 'rgba(179,181,198,0.2)',
        borderColor: 'rgba(179,181,198,1)',
        pointBackgroundColor: 'rgba(179,181,198,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        data: scenarios.map(s => s.other_respondents.work_now_points)
      }
    ]
  }

  const dataRight = {
    labels: labels,
    datasets: [
      {
        label: t('YOUR_ANSWERS'),
        backgroundColor: 'rgba(24, 157, 217, 0.2)',
        borderColor: 'rgba(24, 157, 217, 1)',
        pointBackgroundColor: 'rgba(24, 157, 217, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(24, 157, 217, 1)',
        data: scenarios.map(s => s.respondent.want_to_work_points)
      },
      {
        label: t('YOUR_COLLEAGUES'),
        backgroundColor: 'rgba(179,181,198,0.2)',
        borderColor: 'rgba(179,181,198,1)',
        pointBackgroundColor: 'rgba(179,181,198,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        data: scenarios.map(s => s.other_respondents.want_to_work_points)
      }
    ]
  }

  return (
    <div>
      <h3>{t('YOUR_ANSWERS_AND_YOUR_COLLEAGUES_TITLE')}</h3>
      <p className='lead'>{t('YOUR_ANSWERS_AND_YOUR_COLLEAGUES_BODY')}</p>
      <Row>
        <Col>
          <h5 className='text-center'>{t('HOW_DO_I_WORK_NOW_QUESTION')}</h5>
          <Radar data={dataLeft} options={options} />
        </Col>
        <Col>
          <h5 className='text-center'>{t('HOW_DO_I_WANT_TO_WORK_QUESTION')}</h5>
          <Radar data={dataRight} options={options} />
        </Col>
      </Row>


    </div>
  )
}

export default ScenarioCompare
