import React from 'react'
import { Container } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const ViewClosed = () => {
  const { t, i18n } = useTranslation()
  
  return (
    <Container className='pt-5'>
      <h1>{t('ENQUETE_CLOSED_TITLE')}</h1>
      <p>{t('ENQUETE_CLOSED_BODY')}</p>
    </Container>
  )
}

export default ViewClosed
