const initialState = {
  debug: {
    enabled: false
  },
  uigeneral: {
    title: 'Modern Work Scan'
  },
  general: {
    subTitle: '',
    isSent: false,
    enqueteAccessCode: undefined
  },
  language: 'nl',
  acccessCode: undefined,
  navigation: {
    lastLocation: undefined
  },
  vAlreadySent: {
    gdprCode: ''
  },
  v00Welcome: {
    introText: undefined,
  },
  v01Respondent: {

    isNameEnabled: false,
    isEmailEnabled: false,

    isNameRequired: false,
    isEmailRequired: false,

    freeFields: [
      {
        fieldName: 'sector',
        isEnabled: true,
        placeholder: 'Sector'
      },
      {
        fieldName: 'afdeling',
        isEnabled: true,
        placeholder: 'Afdeling'
      },
      {
        fieldName: 'functie',
        isEnabled: true,
        placeholder: 'Functie',
        options: [
          { id: 0, label: 'adviseur', description: 'Adviseur' },
          { id: 1, label: 'brand_manager', description: 'Brand manager' },
          { id: 2, label: 'ciso', description: 'CISO' },
          { id: 3, label: 'directeur', description: 'Directeur' },
          { id: 4, label: 'developer', description: 'Developer' },
          { id: 5, label: 'functioneel_ontwerper', description: 'Functioneel ontwerper' },
          { id: 6, label: 'groepsmanager', description: 'Groepsmanager' },
          { id: 7, label: 'hrm_medewerker', description: 'HRM Medewerker' },
          { id: 8, label: 'interim_medewerker', description: 'Interim medewerker' },
          { id: 9, label: 'junior_developer', description: 'Junior developer' },
          { id: 11, label: 'kwaliteitscontroleur', description: 'Kwaliteitscontroleur' },
          { id: 12, label: 'lead_architect', description: 'Lead architect' },
          { id: 13, label: 'manager_sales', description: 'Manager sales' },
          { id: 14, label: 'nautisch_specialis', description: 'Nautisch specialis' },
          { id: 15, label: 'operationeel_assistent', description: 'Operationeel_assistent' },
          { id: 16, label: 'projectmanager', description: 'Projectmanager' },
          { id: 17, label: 'qa_assistant', description: 'CISO' },
          { id: 18, label: 'receptionist', description: 'Receptionist' },
          { id: 19, label: 'service_engineer', description: 'Service engineer' },
          { id: 20, label: 'technisch_inkoper', description: 'Technisch inkoper' },
          { id: 21, label: 'ui_designer', description: 'UI designer' },
          { id: 22, label: 'verkoopmedewerker', description: 'CISO' },
          { id: 23, label: 'warehouse_medeweker', description: 'Warehouse medewerker' }
        ]
      }
    ]
  },
  v02Scenarios: {
    scenarios: []
  },
  v04Feedback: {},

  saved: {
    respondent: {
      agreedPrivacy: false,
      name: '',
      email: '',
      freeFields: {}
    },
    answers: {
      //10010: {questionId: 10010, pointsNow: 5, pointsWant: 10, doesNotApply: false},
    }
  }

  
}

export default initialState
