import React, { useEffect } from 'react'
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom'
import View00Welcome from '../views/View00Welcome'
import View01Respondent from '../views/View01Respondent'
import View02Scenarios from '../views/View02Scenarios'
import View03Finish from '../views/View03Finish'
import View04Feedback from '../views/View04Feedback'
import ViewNoAccessCode from '../views/ViewNoAccessCode'
import ViewAlreadySent from '../views/ViewAlreadySent'
import ViewClosed from '../views/ViewClosed'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../state/actions'


const ViewContainer = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const persistedEnqueteAccessCode = useSelector(state => state.general.enqueteAccessCode)
  const lastLocation = useSelector(state => state.navigation.lastLocation)
  const nrOfScenarios = useSelector(state => state.v02Scenarios.scenarios ? state.v02Scenarios.scenarios.length : 0)

  useEffect(() => {
    dispatch(actions.setNavigationLastocation(window.location.pathname))
  }, [window.location.pathname])

  useEffect(() => {
    if (lastLocation !== window.location.pathname 
      && lastLocation !== undefined 
      && lastLocation !== '/gesloten'
      && !(lastLocation.match('^/feedback'))
      && !(window.location.pathname.match('^/feedback'))
      ) {
      const redirectTo = lastLocation
      dispatch(actions.setNavigationLastocation(window.location.pathname))
      history.push(redirectTo)
    }
  }, [])

  return (
    <div>
      <Switch>

        <Route exact path='/enquete/:access_code' render={props => (
          <Redirect to={`/enquete/${props.match.params.access_code}/stap/0`} />
        )} />

        <Route path='/enquete/:access_code/stap/0' render={props => (
          <View00Welcome nextTo={`/enquete/${props.match.params.access_code}/stap/1`} />
        )} />

        <Route path='/enquete/:access_code/stap/1' render={props => (
          <View01Respondent
            previousTo={`/enquete/${props.match.params.access_code}/stap/0`}
            nextTo={`/enquete/${props.match.params.access_code}/stap/2/scenario/0`} />
        )} />

        <Route path='/enquete/:access_code/stap/2/scenario/:id' render={props => (
          <View02Scenarios
            scenarioId={props.match.params.id}
            previousTo={`/enquete/${props.match.params.access_code}/stap/1`}
            nextTo={`/enquete/${props.match.params.access_code}/stap/3`} />
        )} />

        <Route path='/enquete/:access_code/stap/3' render={props => (
          <View03Finish previousTo={`/enquete/${props.match.params.access_code}/stap/2/scenario/${nrOfScenarios - 1}`} />
        )} />

        <Route path='/feedback/:access_code'>
          <View04Feedback />
        </Route>

        <Route path='/enquete/:access_code/al-verzonden'>
          <ViewAlreadySent />
        </Route>

        <Route path='/gesloten'>
          <ViewClosed />
        </Route>

        <Route path='/'>
          <ViewNoAccessCode />
        </Route>
      </Switch>
    </div>
  )
}

export default ViewContainer
