import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './i18n'
import { PersistGate } from 'redux-persist/integration/react'
import {
  BrowserRouter as Router
} from 'react-router-dom'
import { Container } from 'reactstrap'
import 'core-js/es/string'
import 'core-js/es/object'
import App from './App'
import { Provider } from 'react-redux'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import store from './state/store'
window.__initalizedStore = store()

// Redirect if a different enquete is requested
const urlParts = window.location.pathname.replace(/\/\s*$/, '').split('/')
if (urlParts[1] === 'enquete') {
  const requestedAccessCode = urlParts[2]
  if (requestedAccessCode !== window.localStorage.getItem('enqueteAccessCode')) {
    window.localStorage.clear()
    window.localStorage.setItem('enqueteAccessCode', requestedAccessCode)
    window.location = window.location.origin + '/enquete/' + requestedAccessCode
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={window.__initalizedStore.store}>
      <Router>
        <PersistGate loading={null} persistor={window.__initalizedStore.persistor}>
          <App />
        </PersistGate>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)