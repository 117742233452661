import React, { useEffect, useState } from 'react'
import { Collapse, CardHeader, CardBody, Card, Row, Form, Col, FormGroup, Label, Input, UncontrolledPopover, PopoverBody } from 'reactstrap'
import { useForm, useWatch } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory, faQuestion, faChevronDown, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import styled from 'styled-components'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../state/actions'


const AnswerHelpPopOver = ({ target, content }) => {
  return (
    <UncontrolledPopover placement='bottom' target={target} trigger='click hover'>
      <PopoverBody>{content}</PopoverBody>
    </UncontrolledPopover>
  )
}

export default AnswerHelpPopOver