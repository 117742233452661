import React, { useReducer, useEffect } from 'react'
import { collapseReducer, stateFactory, toggleCollapser, closeAll, openCollapser } from '../../utils/collapseReducer'
import Question from './Question'
import QuestionSmall from './QuestionSmall'
import { useMediaPredicate } from 'react-media-hook'
import mediaSizes from '../../utils/media'

const Questions = ({ className, questions = [] }) => {
  const [collapseState, collapseDispatch] = useReducer(collapseReducer, stateFactory(0))
  const smallScreen = useMediaPredicate(mediaSizes.xs)

  useEffect(() => {
    collapseDispatch(closeAll())
    collapseDispatch(toggleCollapser(0))
    window.scrollTo(0, 0)
  }, [questions])

  function handleFinishedQuestion (questionIdx) {
    collapseDispatch(openCollapser(questionIdx + 1))
  }

  const QuestionComponent = smallScreen ? QuestionSmall : Question

  const cards = questions.map((question, idx) => {
    return (
      <QuestionComponent
        choosenNow={0}
        choosenWant={1}
        onFinished={() => handleFinishedQuestion(idx)}
        key={question.id}
        questionData={question}
        isOpen={collapseState[idx]}
        onCollapseToggle={() => collapseDispatch(toggleCollapser(idx))}
      />
    )
  })

  return (
    <div className={className}>
      {cards}
    </div>
  )
}

export default Questions
