import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Container } from 'reactstrap'
import ViewNavBar from '../../elements/ViewNavBar'
import Questions from './Questions'
import {useSelector, useDispatch} from 'react-redux'
import { useHistory } from 'react-router-dom'
import ProgressBalls from '../../elements/ProgressBalls'
import actions from '../../state/actions'
import { Trans, useTranslation } from 'react-i18next'

const BackImage = styled.div`
  height: 200px;
  background: ${p => 'url(/images/scenario_' + p.scenarioIndex + '.jpg)'};
  background-size:cover;
  position: relative;
  background-position: center;

`

const H2 = styled.h2`
  background-color:  rgba(248,249,250,0.82);  
  margin: 0;
  padding: 1rem 1rem;
  color: #189DD9;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
`

const SmallHeader = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #189DD9;
  transition: opacity 200ms;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;

  &.show {
    visibility: visible;
    opacity: 1;
  }
  
`

const Scenario = ({ scenario, scenarioIndex, totalScenarios, onQuestionsFinishedChange  }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const [showSmallHeader, setShowSmallHeader] = useState(false)
  const debugSkipQuestions = useSelector(state => state.debug.skippableQuestions)
  const allAnswers = useSelector(state => state.saved.answers)
  
  useEffect(() => {
    let allQuestionsAnswered = true
    if (!debugSkipQuestions) {
      if (scenario && allAnswers) {
        scenario.questions.forEach(question => {
          const answer = allAnswers[question.id]
          if (answer) {
            if ((answer.pointsNow === 0 || answer.pointsWant === 0) && !answer.doesNotApply) {
              allQuestionsAnswered = false
            }
          }        
        })
      }
    }
    onQuestionsFinishedChange(allQuestionsAnswered)
  }, [allAnswers, scenario, debugSkipQuestions])
  
  useEffect(() => {
    if (scenario) {
      dispatch(actions.setGeneralSubTitle(scenario.title))
    }
  }, [scenario])


  if (!scenario) {
    return <div />
  }
  
  return (
    <div>
      <BackImage scenarioIndex={scenarioIndex}>
        <H2>{scenario.title}</H2>
      </BackImage>
      <Container className='py-4'>
        <div className='mt-2 mb-2'>
          <ProgressBalls steps={totalScenarios + 1} currentStep={scenarioIndex + 1}/>
        </div>
        <div className='lead' dangerouslySetInnerHTML={{__html: scenario.description }} />
        <p>{t('FILL_IN_QUESTIONS_BELOW')}</p>
        <Questions className='mt-5' questions={scenario.questions} />
      </Container>
    </div>
  )
}

const View02Scenarios = ({ scenarioId }) => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const scenarios = useSelector(state => state.v02Scenarios.scenarios)
  const nrScenarios = scenarios.length
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false)
  const params = useParams()
  const currentScenarioIdx = parseInt(scenarioId)
  const savedRespondent = useSelector(state => state.saved.respondent)
  const isSent = useSelector(state => state.general.isSent)

  useEffect(() => {
    if (isSent) {
      history.push(`/enquete/${params.access_code}/al-verzonden`)
    }
  },[isSent])

  function handleQuestionsFinishedChange (questionsAnswered) {
    setAllQuestionsAnswered(questionsAnswered)
  }

  let previousLinkTo
  if (currentScenarioIdx === 0) {
    previousLinkTo = `/enquete/${params.access_code}/stap/1` 
  } else {
    previousLinkTo = `/enquete/${params.access_code}/stap/2/scenario/${currentScenarioIdx - 1}`
  }

  let nextLinkTo
  if (currentScenarioIdx + 1 < nrScenarios) {
    nextLinkTo = `/enquete/${params.access_code}/stap/2/scenario/${currentScenarioIdx + 1}`
  } else {
    nextLinkTo = `/enquete/${params.access_code}/stap/3`
  }
  

  return (
    <div>
      <Scenario 
      scenario={scenarios[currentScenarioIdx]} 
      scenarioIndex={currentScenarioIdx} 
      totalScenarios={scenarios.length}
      onQuestionsFinishedChange={handleQuestionsFinishedChange}
      />      
      <Container>
        <ViewNavBar className='mt-5'  previousTo={previousLinkTo} nextTo={nextLinkTo} previousShow 
          nextShow previousEnabled nextEnabled={allQuestionsAnswered} 
          textNextDisabled={allQuestionsAnswered ? '' : t('ANSWER_ALL_QUESTIONS_FIRST')} />
      </Container>
    </div>
  )
}

export default View02Scenarios
