import React from 'react'
import { Row, Col } from 'reactstrap'
import { HorizontalBar } from 'react-chartjs-2'
import { getRandomInt } from '../../utils/random'
import { useEffect } from 'react'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

const options = {

  plugins: {
    // Change options for ALL labels of THIS CHART
    datalabels: {
      color: '#FFF',
      anchor: 'end',
      align: 'left'
    }
  },
  scales: {
    xAxes: [{
      display: true,
      ticks: {
        suggestedMin: 0,
        suggestedMax: 100
      }
    }]
  }
}

const Improvements = ({ scenarios }) => {
  const [sortedScenariosNow, setSortedScenariosNow] = useState([])
  const [sortedScenariosWant, setSortedScenariosWant] = useState([])
  const { t, i18n } = useTranslation()

  useEffect(() => {
    setSortedScenariosNow(
      scenarios.slice().sort(((a, b) =>  a.respondent.work_now_points - b.respondent.work_now_points ))
    )
    setSortedScenariosWant(
      scenarios.slice().sort(((a, b) =>  b.respondent.want_to_work_points - a.respondent.want_to_work_points ))
    )
  }, [scenarios])


  const dataLeft = {
    labels: sortedScenariosNow.map(s => s.name),
    datasets: [
      {
        label: t('HOW_DO_I_WORK_NOW'),
        backgroundColor: 'rgba(24, 157, 217, 0.8)',
        borderColor: 'rgba(24, 157, 217, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(24, 157, 217, 0.5)',
        hoverBorderColor: 'rgba(24, 157, 217, 1)',
        data: sortedScenariosNow.map(s => s.respondent.work_now_points)
      },
      {
        label: t('AVG_ANSWER_COLLEGAGUES'),
        backgroundColor: 'rgba(179,181,198, 0.8)',
        borderColor: 'rgba(179,181,198, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(179,181,198, 0.5)',
        hoverBorderColor: 'rgba(179,181,198, 1)',
        data: sortedScenariosNow.map(s => s.other_respondents.work_now_points)
      }
    ]
  }

  const dataRight = {
    labels: sortedScenariosWant.map(s => s.name),
    datasets: [
      {
        label: t('HOW_DO_I_WANT_TO_WORK_QUESTION'),
        backgroundColor: 'rgba(24, 157, 217, 0.8)',
        borderColor: 'rgba(24, 157, 217, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(24, 157, 217, 0.5)',
        hoverBorderColor: 'rgba(24, 157, 217, 1)',
        data: sortedScenariosWant.map(s => s.respondent.want_to_work_points)
      },
      {
        label: t('AVG_ANSWER_COLLEGAGUES'),
        backgroundColor: 'rgba(179,181,198, 0.8)',
        borderColor: 'rgba(179,181,198, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(179,181,198, 0.5)',
        hoverBorderColor: 'rgba(179,181,198, 1)',
        data: sortedScenariosWant.map(s => s.other_respondents.want_to_work_points)
      }
    ]
  }



  return (
    <div>
      <h3>{t('WHERE_YOU_IMPROVE_TITLE')}</h3>
      <p className='lead'>{t('WHERE_YOU_IMPROVE_BODY')}</p>

      <Row>
        <Col>
          <h5>{t('YOUR_CURRENT_WORK_METHOD')}</h5>
          <p><i>{t('SORTED_LOW_TO_HIGH')}</i></p>
          <HorizontalBar height={275} data={dataLeft} options={options} />
        </Col>
        <Col>
          <h5>{t('YOUR_DESIRED_WORK_METHOD')}</h5>
          <p><i>{t('SORTED_HIGH_TO_LOW')}</i></p>
          <HorizontalBar height={275} data={dataRight} options={options} />
        </Col>
      </Row>

    </div>
  )
}

export default Improvements
