import React from 'react'
import { Card, CardBody } from 'reactstrap'
import { Trans, useTranslation } from 'react-i18next'

const Intro = () => {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <Card color='primary' outline className='mb-3 ext-card'>
        <CardBody>
          <div>
            <h3>{t('INSIGHT_AND_ACTION_TITLE')}</h3>
            <p>{t('INSIGHT_AND_ACTION_BODY_1')}</p>
            <p>{t('INSIGHT_AND_ACTION_BODY_2')}</p>
          </div>
        </CardBody>
      </Card>
      <Card color='primary' outline className='mb-3 ext-card'>
        <CardBody>
          <div>
            <h3>{t('READING_GUIDE_TITLE')}</h3>
            <p><i>{t('READING_GUIDE_BODY')}</i></p>
          </div>
        </CardBody>
      </Card>

    </div>
  )
}

export default Intro
