const SET_PURGE_STORE = 'SET_PURGE_STORE'
const SET_DEBUG_MODE = 'SET_DEBUG_MODE'
const SET_DEBUG_SKIPPABLE_SCENARIO_QUESTIONS = 'SET_DEBUG_SKIPPABLE_SCENARIO_QUESTIONS'
const SET_RESPONDENT_PRIVACY_AGREEMENT = 'SET_RESPONDENT_PRIVACY_AGREEMENT'
const SET_ACCESS_CODE = 'SET_ACCESS_CODE'
const SET_UI_GENERAL = 'SET_UI_GENERAL'
const SET_LANGUAGE = 'SET_LANGUAGE'
const SET_RESPONDENT_NAME = 'SET_RESPONDENT_NAME'
const SET_RESPONDENT_EMAIL = 'SET_RESPONDENT_EMAIL'
const SET_RESPONDENT_FREE_FIELD = 'SET_RESPONDENT_FREE_FIELD'
const CLEAR_ALL_RESPONDENT_FREE_FIELDS = 'CLEAR_ALL_RESPONDENT_FREE_FIELDS'
const SET_V00_WELCOME = 'SET_V00_WELCOME'
const SET_V01_RESPONDENT = 'SET_V01_RESPONDENT'
const SET_V02_SCENARIOS = 'SET_V02_SCENARIOS'
const SET_ANSWER_NOW = 'SET_ANSWER_NOW'
const SET_ANSWER_WANT = 'SET_ANSWER_WANT'
const SET_ANSWER_DOES_NOT_APPLY = 'SET_ANSWER_DOES_NOT_APPLY'
const SET_RESPONDENT_REMARKS = 'SET_RESPONDENT_REMARKS'
const SET_GENERAL_SUBTITLE = 'SET_GENERAL_SUBTITLE'
const SET_IS_SENT = 'SET_IS_SENT'
const SET_V04_FEEDBACK = 'SET_V04_FEEDBACK'
const SET_GDPR_CODE = 'SET_GDPR_CODE'
const SET_NAVIGATION_LAST_LOCATION = 'SET_NAVIGATION_LAST_LOCATION'
const SET_ENQUETE_ACCESS_CODE = 'SET_ENQUETE_ACCESS_CODE'

const setDebugMode = (debugOn) => ({
  type: SET_DEBUG_MODE,
  payload: debugOn

})

const setDebugSkippableScenarioQuestions = (skippableOn) => ({
  type: SET_DEBUG_SKIPPABLE_SCENARIO_QUESTIONS,
  payload: skippableOn

})

const setPurgeStore = () => ({
  type: SET_PURGE_STORE,
  payload: null

})

const setAccessCode = (payload) => ({
  type: SET_ACCESS_CODE,
  payload: payload

})
const setIsSent = isSent => ({
  type: SET_IS_SENT,
  payload: isSent
})

const setRespondentPrivacyAgreement = doesAgree => ({
  type: SET_RESPONDENT_PRIVACY_AGREEMENT,
  payload: doesAgree
})

const setRespondentName = name => ({
  type: SET_RESPONDENT_NAME,
  payload: name
})
const setRespondentEmail = email => ({
  type: SET_RESPONDENT_EMAIL,
  payload: email
})

const setRespondentRemarks = remarks => ({
  type: SET_RESPONDENT_REMARKS,
  payload: remarks
})

const setRespondentFreeField = (key, value) => ({
  type: SET_RESPONDENT_FREE_FIELD,
  payload: {key, value}
})

const clearAllRespondentFreeFields = () => ({
  type: CLEAR_ALL_RESPONDENT_FREE_FIELDS,
  payload: null

})

const setUIGeneral = payload => ({
  type: SET_UI_GENERAL,
  payload: payload
})
const setLanguage = payload => ({
  type: SET_LANGUAGE,
  payload: payload
})

const setV00Welcome = payload => ({
  type: SET_V00_WELCOME,
  payload: payload
})

const setV01Respondent = payload => ({
  type: SET_V01_RESPONDENT,
  payload: payload
})

const setV02Scenarios = payload => ({
  type: SET_V02_SCENARIOS,
  payload: payload
})

const setAnswerNow = (questionId, points) => ({
  type: SET_ANSWER_NOW,
  payload: {questionId, points}
})

const setAnswerWant = (questionId, points) => ({
  type: SET_ANSWER_WANT,
  payload: {questionId, points}
})

const setAnswerDoesNotApply = (questionId, doesNotApply) => ({
  type: SET_ANSWER_DOES_NOT_APPLY,
  payload: {questionId, doesNotApply}
})

const setGeneralSubTitle = subTitle => ({
  type: SET_GENERAL_SUBTITLE,
  payload: subTitle
})

const setV04Feedback = data => ({
  type: SET_V04_FEEDBACK,
  payload: data
})

const setGdprCode = data => ({
  type: SET_GDPR_CODE,
  payload: data
})

const setNavigationLastocation = data => ({
  type: SET_NAVIGATION_LAST_LOCATION,
  payload: data
})

const setEnqueteAccessCode = data => ({
  type: SET_ENQUETE_ACCESS_CODE,
  payload: data
})

export default {
  SET_PURGE_STORE, setPurgeStore,
  SET_DEBUG_MODE, setDebugMode,
  SET_DEBUG_SKIPPABLE_SCENARIO_QUESTIONS, setDebugSkippableScenarioQuestions,
  SET_RESPONDENT_PRIVACY_AGREEMENT, setRespondentPrivacyAgreement,
  SET_ACCESS_CODE, setAccessCode,
  SET_UI_GENERAL, setUIGeneral,
  SET_LANGUAGE, setLanguage,
  SET_V00_WELCOME, setV00Welcome,
  SET_V01_RESPONDENT, setV01Respondent,
  SET_V02_SCENARIOS, setV02Scenarios,
  SET_RESPONDENT_NAME, setRespondentName,
  SET_RESPONDENT_EMAIL, setRespondentEmail,
  SET_RESPONDENT_REMARKS, setRespondentRemarks,
  SET_RESPONDENT_FREE_FIELD, setRespondentFreeField,
  CLEAR_ALL_RESPONDENT_FREE_FIELDS, clearAllRespondentFreeFields,
  SET_ANSWER_NOW, setAnswerNow,
  SET_ANSWER_WANT, setAnswerWant,
  SET_ANSWER_DOES_NOT_APPLY, setAnswerDoesNotApply,
  SET_GENERAL_SUBTITLE, setGeneralSubTitle,
  SET_IS_SENT, setIsSent,
  SET_V04_FEEDBACK, setV04Feedback,
  SET_GDPR_CODE, setGdprCode,
  SET_NAVIGATION_LAST_LOCATION, setNavigationLastocation,
  SET_ENQUETE_ACCESS_CODE, setEnqueteAccessCode
}
