import React, {useEffect} from 'react'
import { Alert, Button, Container } from 'reactstrap'
import {useDispatch, useSelector} from 'react-redux'
import actions from '../state/actions'
import {useHistory, useParams} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'

const ViewAlreadySent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { t, i18n } = useTranslation()
  const gdprCode = useSelector(state => state.vAlreadySent.gdprCode)

  useEffect(() => {
    dispatch(actions.setGeneralSubTitle(''))
  }, [])

  function handleClick() {
    dispatch(actions.setPurgeStore())
    history.push(`/enquete/${params.access_code}`)
  }

  const feedbackLink = '/feedback/' + gdprCode

  return (
    <Container className='pt-5'>
      <Alert color='info'>{t('ENQUETE_ALREADY_SENT')}</Alert>
      <p className='mt-4'>
      <a href={feedbackLink} className='btn btn-success btn-lg'>{t('YOUR_PERSONAL_PAGE')}{' '}<FontAwesomeIcon icon={faChevronRight}/></a>
      </p>
      <p>        
        <Button onClick={handleClick} color='link' ><small>{t('HAVE_NOT_SENT_ENQUETE')}</small></Button>        
      </p>

    </Container>
  )
}

export default ViewAlreadySent
