import React, { useEffect, useState, useRef } from 'react'
import { Alert, Popover, Modal, ModalBody, ModalFooter, ModalHeader, Button, Container, Card, CardBody, Col, Input, FormGroup, Label, Spinner, Row } from 'reactstrap'
import ViewNavBar from '../elements/ViewNavBar'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import actions from '../state/actions'
import { getEnquete } from '../api/api'
import { Trans, useTranslation } from 'react-i18next'
import LanguageSwitch from '../components/LanguageSwitch'

const View00Welcome = ({
  nextTo
}) => {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const isSent = useSelector(state => state.general.isSent)
  const privacyCheckEl = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showPrivacyPopover, setShowPrivacyPopover] = useState(false)
  const [hasError, setError] = useState(false)
  const [showPrivacyModal, setShowPrivacyModal] = useState(false)
  const dispatch = useDispatch()
  const params = useParams()
  const title = useSelector(state => state.uigeneral?.title)
  const activeLang = useSelector(state => state.language)
  const welcomeTitle = useSelector(state => state.v00Welcome.welcomeTitle)
  const introTextLeft = useSelector(state => state.v00Welcome.introTextLeft)
  const introTextRight = useSelector(state => state.v00Welcome.introTextRight)
  const privacyStatement = useSelector(state => state.v00Welcome.privacyStatement)
  const v01Respondent = useSelector(state => state.v01Respondent)
  const respondent = useSelector(state => state.saved.respondent)
  const logoUrl = useSelector(state => state.v00Welcome.logoUrl)
  const showLogo = useSelector(state => state.v00Welcome.showLogo)

  useEffect(() => {
    window.localStorage.setItem('enqueteAccessCode', params.access_code)
  }, [params.access_code])

  useEffect(() => {
    if (isSent) {
      history.push(`/enquete/${params.access_code}/al-verzonden`)
    }
  }, [isSent])

  useEffect(() => {
    dispatch(actions.setGeneralSubTitle(''))
  }, [])

  useEffect(() => {
    dispatch(actions.setAccessCode(params.access_code))
  }, [params.access_code])

  useEffect(() => {
    setIsLoading(true)
    getEnquete(params.access_code).then(data => {
      if (data.status === 'ENQUETE_CLOSED') {
        history.push('/gesloten')
      } else {
        dispatch(actions.setUIGeneral(data.uigeneral))
        dispatch(actions.setV00Welcome({
          welcomeTitle: data.v00welcome.welcomeTitle,
          introTextLeft: data.v00welcome.introTextLeft,
          introTextRight: data.v00welcome.introTextRight,
          privacyStatement: data.v00welcome.privacyStatement,
          logoUrl: data.v00welcome.logoUrl,
          showLogo: data.v00welcome.showLogo,
        }))
        dispatch(actions.setV01Respondent(data.v01respondent))
        dispatch(actions.setV02Scenarios(data.v02scenarios))
        setIsLoading(false)
      }
    }).catch(() => {
      setIsLoading(false)
      setError(true)
    })

  }, [params.access_code])

  function handleAgreeChange(e) {
    dispatch(actions.setRespondentPrivacyAgreement(e.target.checked))
  }

  function handlePrivacyClick(e) {
    e.preventDefault()
    setShowPrivacyModal(true)
    return false
  }


  return (
    <Container className='mt-5 pt-5'>

      {hasError &&
        <div className='mt-5 pt-5'>
          <Alert color='danger'>{t('SOMETHING_WHENT_WRONG')}</Alert>
        </div>}
      {!hasError && <div>
        {showLogo && <div className='text-center py-1 mb-2'>
          <img src={logoUrl} style={{ maxWidth: '100%', maxHeight: '150px' }} alt='Logo' />
        </div>}
        {!isLoading && <LanguageSwitch light className='mt-5 justify-content-end' size='lg' />}
        <h1>{welcomeTitle}</h1>

        {isLoading ? <div className='mt-5 pt-5 text-center'>
          <p>{t('LOADING_ENQUETE')}</p>
          <Spinner color='primary' type='grow' />
        </div> :
          <div>




            <div className='mb-3' dangerouslySetInnerHTML={{ __html: introTextLeft }} />
            <div className='mb-3' dangerouslySetInnerHTML={{ __html: introTextRight }} />

            <Card color={respondent.agreedPrivacy ? 'success' : 'primary'} outline className='mb-3 ext-card'>
              <CardBody>
                <p>
                  <Trans i18nKey='PRIVACY_STATEMENT_APPLIES' t={t} title={title}>
                  Op deze {{title}} is het privacystatement van toepassing dat <a onClick={handlePrivacyClick} href='#' rel='noopener noreferrer'>hier</a> is te lezen.
                </Trans>
                </p>

                <FormGroup row>
                  <Col sm={{ size: 10 }}>
                    <FormGroup check>
                      <Label check>
                        <Input innerRef={privacyCheckEl} checked={respondent.agreedPrivacy} onChange={handleAgreeChange} type='checkbox' id='check_privacy_agreement' />{' '}
                        <i style={{ fontWeight: 'bolder' }}>{t('I_AGREE_PRIVACY_STATEMENT')}</i>
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>
              </CardBody>
            </Card>

            {privacyCheckEl.current && <Popover
              trigger='legacy'
              placement="bottom"
              isOpen={!respondent.agreedPrivacy && showPrivacyPopover}
              target={privacyCheckEl.current}
              toggle={() => setShowPrivacyPopover(!showPrivacyPopover)}>
              <p className='m-2'>{t('PRIVACY_STATEMENT_AGREE_POPOVER')}</p>
            </Popover>}

            <ViewNavBar
              className='mt-5'
              nextTo={nextTo}
              previousShow={false}
              nextEnabled={respondent.agreedPrivacy}
              onNextDisabledClick={() => setShowPrivacyPopover(true)}
            />
          </div>}
        <Modal isOpen={showPrivacyModal} toggle={() => setShowPrivacyModal(!showPrivacyModal)}>
          <ModalHeader toggle={() => setShowPrivacyModal(!showPrivacyModal)}>Privacystatement</ModalHeader>
          <ModalBody><div dangerouslySetInnerHTML={{ __html: privacyStatement }} /></ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => setShowPrivacyModal(!showPrivacyModal)}>Sluiten</Button>
          </ModalFooter>
        </Modal>
      </div>}



    </Container>
  )
}

export default View00Welcome
