import React, { useEffect, useState, useRef } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import ViewNavBar from '../../elements/ViewNavBar'
import {
  Row, Col, FormGroup, Input, InputGroup, Card, CardHeader, FormText, InputGroupText, Container,
  CardBody, UncontrolledPopover, InputGroupAddon, PopoverBody, Form, FormFeedback, Alert
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserTag, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import AutoComplete from '../../elements/AutoComplete'
import { emailPattern } from '../../utils/hookForm'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../state/actions'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const NamePopOver = ({ target, text }) => {
  return (
    <UncontrolledPopover placement='bottom' target={target} trigger='click hover'>
      <PopoverBody>{text}</PopoverBody>
    </UncontrolledPopover>
  )
}

const EmailPopOver = ({ target, text }) => {
  return (
    <UncontrolledPopover placement='bottom' target={target} trigger='click hover'>
      <PopoverBody>{text}</PopoverBody>
    </UncontrolledPopover>
  )
}

const View01Respondent = ({ nextTo, previousTo }) => {
  const { register, unregister, errors, formState, reset, setValue, getValues, trigger, control } = useForm({
    mode: 'onTouched'
  })
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const nameEl = useRef()
  const emailEl = useRef()
  const [errorFreeField, setErrorFreeFields] = useState(false)
  const [freeFieldsTouched, setFreeFieldsTouched] = useState(false)
  const isSent = useSelector(state => state.general.isSent)
  const privacyAgreed = useSelector(state => state.v01Respondent.freeFields)
  const domainTitle = useSelector(state => state.v01Respondent.domainTitle)
  const freeFields = useSelector(state => state.v01Respondent.freeFields)
  const nameEnabled = useSelector(state => state.v01Respondent.isRespondentNameFieldEnabled)
  const nameRequired = useSelector(state => state.v01Respondent.isRespondentNameFieldRequired)
  const nameHelpText = useSelector(state => state.v01Respondent.nameFieldHelpText)
  const emailEnabled = useSelector(state => state.v01Respondent.isRespondentEmailFieldEnabled)
  const emailRequired = useSelector(state => state.v01Respondent.isRespondentEmailFieldRequired)
  const emailHelpText = useSelector(state => state.v01Respondent.emailFieldHelpText)
  const savedRespondent = useSelector(state => state.saved.respondent)

  const freeFieldKeys = freeFields.map(f => f.fieldName)
  const freeFieldKeysRequired = freeFields.reduce((filtered, field) => {
    if (field.isRequired) {
      filtered.push(field.fieldName)
    }
    return filtered
  }, [])
  const freeFieldValues = []

  const watchFreeFields = useWatch({ control, name: freeFieldKeys })
  const watchAll = useWatch({ control })

  useEffect(() => {
    if (isSent) {
      history.push(`/enquete/${params.access_code}/al-verzonden`)
    }
  },[isSent])

  useEffect(() => {
    dispatch(actions.setGeneralSubTitle(''))
  }, [])

  useEffect(() => {
    if (!savedRespondent.agreedPrivacy) {
      history.push('/')
    }
  }, [savedRespondent])

  useEffect(() => {
    dispatch(actions.setRespondentName(getValues('name')))
    dispatch(actions.setRespondentEmail(getValues('email')))
    dispatch(actions.clearAllRespondentFreeFields())
    freeFieldKeys.forEach(freeFieldKey => {
      if (getValues(freeFieldKey)) {
        dispatch(actions.setRespondentFreeField(freeFieldKey, getValues(freeFieldKey)))
      }
    })
  }, [watchAll])

  useEffect(() => {
    if (freeFields) {
      let nrRequiredFieldsFilled = 0
      freeFieldKeysRequired.forEach(key => {
        if (getValues(key) !== undefined && getValues(key) !== "") {
          nrRequiredFieldsFilled++
        }
      })
      if (nrRequiredFieldsFilled >= freeFieldKeysRequired.length) {
        setErrorFreeFields(false)
      } else {
        setErrorFreeFields(true)
      }
    }
  }, [watchFreeFields])

  useEffect(() => {
    const initialValues = {
      name: savedRespondent.name,
      email: savedRespondent.email
    }
    Object.keys(savedRespondent.freeFields).forEach(key => initialValues[key] = savedRespondent.freeFields[key])
    reset(initialValues)
  }, [reset])

  useEffect(() => {
    freeFieldKeys.forEach(key => register({ name: key }))
    return () => {
      freeFieldKeys.forEach(key => unregister({ name: key }))
    }
  }, [register, unregister])

  const showFreeFieldsError = errorFreeField && freeFieldsTouched

  const freeFieldGroups = freeFields?.map(field => {
    const isInvalid = 
      freeFieldsTouched && field.isRequired && (getValues(field.fieldName) === undefined || getValues(field.fieldName) === "")
    return (<Row noGutters key={field.fieldName}>
      <Col xs='11'>
        <FormGroup >
          {field.options ? <AutoComplete
            invalid={isInvalid}
            dataSource={field.options}
            onBlur={() => setFreeFieldsTouched(true)}
            defaultSearchValue={field.options.find(option => option.label === getValues(field.fieldName))?.description}
            placeholder={field.placeholder}
            onSelected={e => {
              setValue(field.fieldName, e.label)
              trigger(field.fieldName)
            }}
          /> : <Input invalid={isInvalid} onBlur={() => setFreeFieldsTouched(true)} key={field.fieldName} innerRef={register()} name={field.fieldName} id={field.fieldName} placeholder={field.placeholder} />
          }
        </FormGroup>
      </Col>
        <Col xs='1' className={'pl-2 ' + (isInvalid ? 'text-danger' : '')}>{field.isRequired && '*'}</Col>        
    </Row>)
    }
  )

  return (
    <Container className='mt-5 pt-5'>
      <div>
        <Form>
          <Row>
            {(nameEnabled || emailEnabled) && <Col sm='12' md='6' className='pb-3'>
              <Card className={'ext-card ' + (formState.isValid ? 'primary' : '')}>
                <CardHeader><FontAwesomeIcon icon={faUser} fixedWidth className='mr-2' />{t('ABOUT_YOU')}</CardHeader>
                <CardBody>
                  {nameEnabled && <FormGroup>
                    <div className='d-flex align-items-center'>
                      <InputGroup>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText><span ref={nameEl}><FontAwesomeIcon icon={faInfoCircle} fixedWidth /></span></InputGroupText>
                        </InputGroupAddon>
                        <Input invalid={!!errors.name} innerRef={register({ required: { value: nameRequired, message: t('NAME_IS_REQUIRED') } })} name='name' id='name' placeholder={t('YOUR_NAME')} />
                        <FormFeedback valid={!errors.name}>{errors.name && errors.name.message}</FormFeedback>
                      </InputGroup>
                    </div>
                    {nameRequired && <FormText>{t('REQUIRED')}</FormText>}
                    {nameEl.current && <NamePopOver text={nameHelpText} target='name_info' target={nameEl.current}  />}
                  </FormGroup>}
                  {emailEnabled && <FormGroup>
                    <div className='d-flex align-items-center'>
                      <InputGroup>
                        <InputGroupAddon addonType='prepend'>
                          <InputGroupText><span ref={emailEl}><FontAwesomeIcon icon={faInfoCircle} fixedWidth /></span></InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type='email' invalid={!!errors.email} innerRef={register({
                            required: { value: emailRequired, message: t('EMAIL_IS_REQUIRED') },
                            pattern: emailPattern
                          })} name='email' id='email' placeholder={t('YOUR_EMAIL')}
                        />
                        <FormFeedback valid={!errors.email}>{errors.email && errors.email.message}</FormFeedback>
                      </InputGroup>
                    </div>
                    {emailRequired && <FormText>{t('REQUIRED')}</FormText>}
                    {emailEl.current && <EmailPopOver text={emailHelpText} target={emailEl.current} />}
                  </FormGroup>}
                </CardBody>
              </Card>
            </Col>}
            <Col sm='12' md='6'>
              {freeFieldGroups && <Card className={'ext-card ' + (!errorFreeField ? 'primary' : '')}>
                <CardHeader><FontAwesomeIcon icon={faUserTag} fixedWidth className='mr-2' />{domainTitle}</CardHeader>
                <CardBody>
                  {freeFieldGroups}
                  {showFreeFieldsError &&
                    <span className='text-danger'><small>{t('FIELDS_*_REQUIRED')}</small></span>
                  }
                </CardBody>
              </Card>}
            </Col>
          </Row>
        </Form>
        <p className='text-right mt-2'><i>{t('START_ENQUETE_BY_NEXT')}</i></p>
        <ViewNavBar previousTo={previousTo} nextTo={nextTo} previousShow nextEnabled={formState.isValid && !errorFreeField} />
      </div>
    </Container>
  )
}

export default View01Respondent
