import { createStore } from 'redux'
import { createReducer } from '@reduxjs/toolkit'
import initialState from './initialState'
import actions from './actions'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistVersion = 'v5'

const mainReducer = createReducer(initialState, {
  [actions.SET_PURGE_STORE]: (state, action) => {
    return initialState
  },
  [actions.SET_DEBUG_MODE]: (state, action) => {
    if (action.payload) {
      state.debug.enabled = true
    } else {
      state.debug = {enabled: false}
    }
    
  },  
  [actions.SET_DEBUG_SKIPPABLE_SCENARIO_QUESTIONS]: (state, action) => {
    state.debug.skippableQuestions = action.payload
  },
  [actions.SET_IS_SENT]: (state, action) => {
    state.general.isSent = action.payload
  },
  [actions.SET_RESPONDENT_PRIVACY_AGREEMENT]: (state, action) => {
    state.saved.respondent.agreedPrivacy = action.payload
  },
  [actions.SET_RESPONDENT_NAME]: (state, action) => {
    state.saved.respondent.name = action.payload
  },
  [actions.SET_RESPONDENT_EMAIL]: (state, action) => {
    state.saved.respondent.email = action.payload
  },
  [actions.SET_RESPONDENT_REMARKS]: (state, action) => {
    state.saved.respondent.remarks = action.payload
  },
  [actions.SET_RESPONDENT_FREE_FIELD]: (state, action) => {
    state.saved.respondent.freeFields[action.payload.key] = action.payload.value
  },
  [actions.CLEAR_ALL_RESPONDENT_FREE_FIELDS]: (state, action) => {
    state.saved.respondent.freeFields = {}
  },
  [actions.SET_LANGUAGE]: (state, action) => {
    state.language = action.payload
  },
  [actions.SET_ACCESS_CODE]: (state, action) => {
    state.accessCode = action.payload
  },
  [actions.SET_UI_GENERAL]: (state, action) => {
    state.uigeneral = action.payload
  },
  [actions.SET_V00_WELCOME]: (state, action) => {
    state.v00Welcome = action.payload
  },
  [actions.SET_V01_RESPONDENT]: (state, action) => {
    state.v01Respondent = action.payload
  },
  [actions.SET_V02_SCENARIOS]: (state, action) => {
    state.v02Scenarios = action.payload
  },
  [actions.SET_ANSWER_NOW]: (state, action) => {
    const id = action.payload.questionId
    let points = action.payload.points
    if (points <= 0) {
      points = 0
    }
    if (!state.saved.answers[id]) {
      state.saved.answers[id] = {questionId: id, pointsNow: points, pointsWant: 0, doesNotApply: false}
    } else {
      state.saved.answers[id].pointsNow = points
    }
  },
  [actions.SET_ANSWER_WANT]: (state, action) => {
    const id = action.payload.questionId
    let points = action.payload.points
    if (points <= 0) {
      points = 0
    }
    if (!state.saved.answers[id]) {
      state.saved.answers[id] = {questionId: id, pointsNow: 0, pointsWant: points, doesNotApply: false}
    } else {
      state.saved.answers[id].pointsWant = points
    }
  },
  [actions.SET_ANSWER_DOES_NOT_APPLY]: (state, action) => {
    const id = action.payload.questionId
    let doesNotApply = !!action.payload.doesNotApply
    if (!state.saved.answers[id]) {
      state.saved.answers[id] = {questionId: id, pointsNow: 0, pointsWant: 0, doesNotApply: doesNotApply}
    } else {
      state.saved.answers[id].doesNotApply = doesNotApply
    }
  },
  [actions.SET_GENERAL_SUBTITLE]: (state, action) => {
    state.general.subTitle = action.payload
  },
  
  [actions.SET_V04_FEEDBACK]: (state, action) => {
    state.v04Feedback = action.payload
  },
  
  [actions.SET_GDPR_CODE]: (state, action) => {
    state.vAlreadySent.gdprCode = action.payload
  },
  
  [actions.SET_NAVIGATION_LAST_LOCATION]: (state, action) => {
    state.navigation.lastLocation = action.payload
  },
  
  [actions.SET_ENQUETE_ACCESS_CODE]: (state, action) => {
    state.general.enqueteAccessCode = action.payload
  },

})

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, mainReducer)


export default () => {
  let store = createStore(persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
  let persistor = persistStore(store)
  if (window.localStorage.getItem('persistVersion') !== persistVersion) {
    window.localStorage.setItem('persistVersion', persistVersion)
    persistor.purge()
  }
  window.__persistor = persistor  
  return { store, persistor }
}

