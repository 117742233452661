import React, { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Popover } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const ViewNavBar = ({
  className,
  previousEnabled = true,
  nextEnabled = false,
  previousShow = true,
  nextShow = true,
  previousTo,
  nextTo,
  textNextDisabled,
  onNextDisabledClick = () => null
}) => {
  const { t, i18n } = useTranslation()
  const nextEl = useRef(null)
  const classNext = classNames('btn btn-primary ml-auto', nextEnabled ? '' : 'disabled')
  const classPrevious = classNames('btn btn-outline-secondary', previousEnabled ? '' : 'disabled')
  const [showNextPopover, setShowNextPopover] = useState(false)

  function handleNextContainerClick() {
    if (nextEnabled) {
      return true
    } else {
      textNextDisabled && setShowNextPopover(true)
      onNextDisabledClick()
    }
  }


  return (
    <div className={className}>
      <div className='d-flex'>
        {previousShow &&
          <Link className={classPrevious} to={previousTo}><FontAwesomeIcon className='mr-3' icon={faChevronLeft} />{t('PREVIOUS')}</Link>}
        {nextShow &&
          <span onClick={handleNextContainerClick} className='ml-auto'>
            <Link ref={nextEl} className={classNext} to={nextTo}>{t('NEXT')}<FontAwesomeIcon className='ml-3' icon={faChevronRight} /></Link>
          </span>}
        {nextEl.current && <Popover trigger='legacy' placement="top" isOpen={showNextPopover && !nextEnabled} target={nextEl.current} toggle={() => setShowNextPopover(!showNextPopover)}>
          <p className='m-2'>{textNextDisabled}</p>
        </Popover>}
      </div>
    </div>
  )
}

export default ViewNavBar
