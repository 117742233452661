import React from 'react'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import actions from '../state/actions'
import flagNl from '../scss/assets/flag_nl.svg'
import flagEn from '../scss/assets/flag_en.svg'
import flagEs from '../scss/assets/flag_es.svg'

const enabledLanguages = [
  {key: 'nl', title: 'Nederlands', img: flagNl},
  {key: 'en', title: 'English', img: flagEn},
  {key: 'es', title: 'Español', img: flagEs}
]

const StyledContainer = styled.div`
  display: flex;
`

const StyledFlag = styled.div`
  width: ${p => p.size === 'lg' ? '50px' : '25px'};
  margin-left: 0.5rem;
  .flag {
    width: ${p => p.size === 'lg' ? '50px' : '25px'};
    height: ${p => p.size === 'lg' ? '36px' : '18px'};
    background-image: ${p => `url('${p.flagImg}')`};
    background-position: center;
    background-size: cover;
    transition: opacity 200ms;
    border: solid 1px #3f6171;
    opacity: ${p => p.active ? '1.0' : '0.5'};
    &:hover {
      ${p => !p.active && 'cursor: pointer'};
      ${p => !p.active && 'opacity: 0.8'};
    }
  }

  .active {
    margin-top: ${p => p.size === 'lg' ? '5px' : '3px'};
    width: ${p => p.size === 'lg' ? '50px' : '25px'};
    height: ${p => p.size === 'lg' ? '4px' : '2px'};
    background-color: ${p => p.light ? '#189DD9' : 'white'};
    opacity: 1;
  }

`

const Flag = ({flagImg, active, onClick, title, size, light}) => {
  return (
    <StyledFlag flagImg={flagImg} active={active} onClick={onClick} title={title} size={size} light={light}>
      <div className='flag' />
      {active && <div className='active' />}
    </StyledFlag>
  )
}

const LanguageSwitch = ({className, size = 'sm', light = false}) => {
  const dispatch = useDispatch()
  const activeLang = useSelector(state => state.language)

  const flags = enabledLanguages.map(f => <Flag key={f.key} size={size} light={light}
    flagImg={f.img} active={f.key === activeLang} title={f.title}
    onClick={() => dispatch(actions.setLanguage(f.key))}
  />)
  
  return (
    <StyledContainer className={className} >      
      {flags}
    </StyledContainer>
  )
}

export default LanguageSwitch
