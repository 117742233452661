import React, {useEffect} from 'react'
import { Alert, Container } from 'reactstrap'
import {useDispatch} from 'react-redux'
import actions from '../state/actions'
import { useTranslation } from 'react-i18next'

const ViewNoAccessCode = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    dispatch(actions.setGeneralSubTitle(''))
  }, [])

  return (
    <Container className='pt-5'>
      <Alert color='danger'>{t('NO_ENQUETE_CODE_FOUND')}</Alert>
    </Container>
  )
}

export default ViewNoAccessCode
