import React, { useEffect, useState } from 'react'
import { Collapse, CardHeader, CardBody, Card, Row, FormGroup, Col, Input, Label, CardFooter } from 'reactstrap'
import { useForm, useWatch } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory, faQuestion, faChevronDown, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons'
import styled from 'styled-components'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../state/actions'
import AnswerHelpPopOver from './AnswerHelpPopover'
import { Trans, useTranslation } from 'react-i18next'


const ExtCardHeader = styled(CardHeader)`
  display: flex;
  align-items: center;
  transition: background-color 200ms;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.10);
  }
`
const RadioBox = ({ id, name, innerRef, value, color = '' }) => {
  return (
    <div className='custom-control custom-radio '>
      <input type='radio' ref={innerRef} id={'radio_' + id} name={name} value={value} className='custom-control-input' />
      <label className={'custom-control-label ' + color} htmlFor={'radio_' + id} />
    </div>

  )
}
const QuestionSmall = ({ onCollapseToggle, questionData, isOpen, onFinished = () => null }) => {
  const {t} = useTranslation()
  const { register, getValues, reset, control, setValue } = useForm({
    mode: 'onTouched'
  })
  const dispatch = useDispatch()
  const answerState = useSelector(state => state.saved.answers[questionData?.id])
  const [hasAnsweredNow, setHasAnsweredNow] = useState(false)
  const [hasAnsweredWant, setHasAnsweredWant] = useState(false)
  const [doesNotApply, setDoesNotApply] = useState(false)


  const nameElementNow = 'question_' + questionData.id + '_now'
  const nameElementWant = 'question_' + questionData.id + '_want'
  const nameDoesNotApply = 'question_' + questionData.id + '_does_not_apply'

  const watchFields = useWatch({ control })
  const watchDoesNotApply = useWatch({ control, name: nameDoesNotApply })  
  const watchQuestionNow = useWatch({control, name: nameElementNow})
  const watchQuestionWant = useWatch({control, name: nameElementWant})


  useEffect(() => {
    setHasAnsweredNow(getValues(nameElementNow) !== undefined && getValues(nameElementNow) !== '')
    setHasAnsweredWant(getValues(nameElementWant) !== undefined && getValues(nameElementWant) !== '')
  }, [watchFields])

  useEffect(() => {
    setDoesNotApply(getValues(nameDoesNotApply))
  }, [watchDoesNotApply])

  useEffect(() => {
    if (doesNotApply) {
      setValue(nameElementNow, undefined)
      setValue(nameElementWant, undefined)
    }
  }, [doesNotApply])

  useEffect(() => {
    let points = parseInt(getValues(nameElementNow))
    if (isNaN(points)) { points = 0 }
    dispatch(actions.setAnswerNow(questionData.id, points))
  }, [watchQuestionNow])  
  
  useEffect(() => {
    let points = parseInt(getValues(nameElementWant))
    if (isNaN(points)) { points = 0 }
    dispatch(actions.setAnswerWant(questionData.id, points))
  }, [watchQuestionWant])  

  useEffect(() => {
    const state = getValues(nameDoesNotApply)
    dispatch(actions.setAnswerDoesNotApply(questionData.id, state))
  }, [watchDoesNotApply])


  useEffect(() => {
    if (hasAnsweredWant || doesNotApply) {
      onFinished()
    }
  }, [hasAnsweredWant, doesNotApply])

  useEffect(() => {
    if (hasAnsweredNow && questionData.noNowAndWantCols) {
      onFinished()
    }
  }, [hasAnsweredNow, questionData.noNowAndWantCols])


  useEffect(() => {
    if (answerState) {
      const initialValues = {
        [nameElementNow]: answerState.pointsNow.toString(),
        [nameElementWant]: answerState.pointsWant.toString(),
        [nameDoesNotApply]: answerState.doesNotApply
      }    
      reset(initialValues)
    }    
  }, [reset])





  const nowAnswers = questionData?.answers?.map((answer, idx) => {
    const value = answer.points
    return (
      <Row key={answer.id} >
        <Col xs='1'><RadioBox color='secondary' value={value} innerRef={register()} name={nameElementNow} id={'answer_' + answer.id + '_now'} /></Col>
        <Col>
          <span onClick={e => setValue(nameElementNow, value.toString())}>{answer.answerText}</span>
          {answer.helpText && <div className='d-inline'>
            <FontAwesomeIcon className='ml-2' icon={faInfoCircle} id={`answer_text_now_${answer.id}`} />
            <AnswerHelpPopOver target={`answer_text_now_${answer.id}`} content={answer.helpText}/>
          </div>}          
        </Col>
      </Row>
    )
  })

  const wantAnswers = questionData?.answers?.map((answer, idx) => {
    const value = answer.points
    return (
      <Row key={answer.id} >
        <Col xs='1'><RadioBox color='primary' value={value} innerRef={register()} name={nameElementWant} id={'answer_' + answer.id + '_want'} /></Col>
        <Col>
          <span onClick={e => setValue(nameElementWant, value.toString())}>{answer.answerText}</span>
          {answer.helpText && <div className='d-inline'>
            <FontAwesomeIcon className='ml-2' icon={faInfoCircle} id={`answer_text_want_${answer.id}`} />
            <AnswerHelpPopOver target={`answer_text_want_${answer.id}`} content={answer.helpText}/>
          </div>}          
        </Col>
      </Row>
    )
  })

  const titleClassNames = classNames('m-0', { 'text-muted': doesNotApply })
  const cardClasses = classNames('ext-card mb-2', { primary: hasAnsweredWant }, { 'opacity-60': doesNotApply })

  return (
    <Card key={questionData.id} className={cardClasses}>
      <ExtCardHeader onClick={onCollapseToggle}>
        <h5 className={titleClassNames}>{questionData.questionText}</h5>
        {!isOpen && <FontAwesomeIcon icon={faChevronDown} fixedWidth className='ml-auto' />}
      </ExtCardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <Collapse isOpen={!doesNotApply}>
            <div>
              {!questionData.noNowAndWantCols && <h6><strong>{t('HOW_DO_I_WORK_NOW')}</strong></h6> }
              {nowAnswers}
              <div className={(!doesNotApply && hasAnsweredNow && !questionData.noNowAndWantCols) ? '' : 'd-none'}>
                <hr />
                <h6 className='text-primary'><strong>{t('HOW_DO_I_WANT_TO_WORK')}</strong></h6>
                {wantAnswers}
              </div>
              <hr />
            </div>
          </Collapse>
          <FormGroup check>
            <Label check>
              <Input innerRef={register()} name={nameDoesNotApply} type='checkbox' id={'question_' + questionData.id + '_does_not_apply'} />{' '}
              <i>{t('DOES_NOT_APPLY_TO_ME')}</i>
            </Label>
          </FormGroup>

        </CardBody>
      </Collapse>
    </Card>
  )
}

export default QuestionSmall
