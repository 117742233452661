import React, { useEffect } from 'react'
import './scss/style.scss'
import { Provider, useDispatch, useSelector } from 'react-redux'
import TopBar from './containers/TopBar'
import ViewContainer from './containers/ViewContainer'
import BottomBar from './containers/BottomBar'
import actions from './state/actions'
import { useTranslation } from 'react-i18next'
import {
  BrowserRouter as Router, useParams
} from 'react-router-dom'
import styled from 'styled-components'
import { getEnquete } from './api/api'

const QaBar = styled.div`
  font-size: 70%;
  padding: 0.1rem;
  background-color: #306725;
  color: white;
  width: 100%;
  text-align: center;
`

const qaMode = document.getElementById('root').dataset.userEnvironment === 'qa'

if (qaMode) {
  document.title = 'QA Modern Work Scan'
}
function App() {
  const dispatch = useDispatch()
  const params = useParams()
  const activeLang = useSelector(state => state.language)
  const accessCode = useSelector(state => state.accessCode)
  const { i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(activeLang)
  }, [activeLang])

  useEffect(() => {
    if (accessCode) {

      getEnquete(accessCode).then(data => {
        if (data.status !== 'ENQUETE_CLOSED') {
          dispatch(actions.setUIGeneral(data.uigeneral))
          dispatch(actions.setV00Welcome({
            welcomeTitle: data.v00welcome.welcomeTitle,
            introTextLeft: data.v00welcome.introTextLeft,
            introTextRight: data.v00welcome.introTextRight,
            privacyStatement: data.v00welcome.privacyStatement,
            logoUrl: data.v00welcome.logoUrl,
            showLogo: data.v00welcome.showLogo,
          }))
          dispatch(actions.setV01Respondent(data.v01respondent))
          dispatch(actions.setV02Scenarios(data.v02scenarios))
        }
      })
    }
  }, [accessCode, activeLang])

  return (
    <div style={{ minHeight: '100vh' }} className='position-relative'>
      <div style={{ paddingBottom: '125px' }}>
        <TopBar />
        {qaMode && <QaBar>QA omgeving</QaBar>}
        <ViewContainer />
      </div>
      <BottomBar />
    </div>
  )
}

export default App
