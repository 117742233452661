export const openCollapser = collapseId => ({ type: 'open', payload: collapseId })
export const toggleAll = collapseId => ({ type: 'toggle_all' })
export const closeAll = collapseId => ({ type: 'close_all' })
export const toggleCollapser = collapseId => ({ type: 'toggle', payload: collapseId })

export function stateFactory (nrOfTogglers) {
  const retVal = {}
  for (let i = 0; i < nrOfTogglers; i++) {
    retVal[i] = false
  }
  return retVal
}
export function collapseReducer (state, action) {
  const newState = {}
  let newToggletatus
  switch (action.type) {
    case 'open':
      return { ...state, [action.payload]: true }
    case 'close':
      return { ...state, [action.payload]: false }
    case 'toggle':
      return { ...state, [action.payload]: !(state[action.payload]) }
    case 'toggle_all':
      newToggletatus = 'open'
      for (const key in Object.keys(state)) {
        if (state[key]) {
          newToggletatus = 'close'
        }
      }
      Object.keys(state).forEach(key => {
        newState[key] = newToggletatus === 'open'
      })
      return newState
    case 'close_all':
      Object.keys(state).forEach(key => {
        newState[key] = false
      })
      return newState
    default:
      return state
  }
}
