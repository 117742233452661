import React from 'react'
import logo from '../scss/assets/logo1uur.svg'
import { useMediaPredicate } from 'react-media-hook'
import mediaSizes from '../utils/media'

const BottomBar = () => {

  return (
    <div>
      <div className='p-3 d-flex position-absolute w-100' style={{bottom: '0', height: '125px'}}>
        <img style={{ height: '72px' }} className='ml-auto' src={logo} alt='Logo' />
      </div>
    </div>
  )
}

export default BottomBar
