import React, { useEffect, useState } from 'react'
import { ListGroup, ListGroupItem, Collapse, Badge, Card, CardHeader, CardBody } from 'reactstrap'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltLeft, faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { Trans, useTranslation } from 'react-i18next'

const ExtCardHeader = styled(CardHeader)`
  display: flex;
  align-items: center;
  transition: background-color 200ms;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.10);
  }
`

const Answer = ({ answer, workNow = false, wantToWork = false }) => {
  const { t, i18n } = useTranslation()
  let badge = null

  if (workNow) {
    badge = <Badge className='ml-2 py-2 px-2' color='primary'><FontAwesomeIcon icon={faArrowAltLeft} />{' '}{t('THIS_IS_HOW_I_WORK_NOW')}</Badge>
  }
  if (wantToWork) {
    badge = <Badge className='ml-2 py-2 px-2' color='primary'><FontAwesomeIcon icon={faArrowAltLeft} />{' '}{t('THIS_IS_HOW_I_WANT_WORK')}</Badge>
  }
  if (workNow && wantToWork) {
    badge = <Badge className='ml-2 py-2 px-2' color='primary'><FontAwesomeIcon icon={faArrowAltLeft} />{' '}{t('THIS_IS_HOW_I_WORK_AND_WANT_TO_WORK')}</Badge>
  }

  return (
    <ListGroupItem>
      <span>{answer.answerText}{badge}</span>
    </ListGroupItem>
  )
}
const Question = ({ question }) => {
  const { t, i18n } = useTranslation()

  const listItems = question.answers.map(a => {
    const workNow = question.respondent_work_now_points === a.points
    const wantToWork = question.respondent_want_to_work_points === a.points
    return (<Answer workNow={workNow} wantToWork={wantToWork} key={a.id} answer={a} />)
  }
  )
  const classes = question.does_not_apply ? 'text-muted' : ''
  return (
    <div className='mb-3 pl-4'>
      <h5 className={classes}>{question.questionText}</h5>
      {!question.does_not_apply && <ListGroup className='ml-5'>
        {listItems}
      </ListGroup>}
      {question.does_not_apply && <p className='text-muted'><small>{t('DOES_NOT_APPLY_TO_YOU')}</small></p>}

    </div>
  )
}



const Scenario = ({ scenario }) => {
  const [isOpen, setIsOpen] = useState(false)

  function onCollapseToggle() {
    setIsOpen(!isOpen)
  }

  const questionParts = scenario.questions.map(q => <Question key={q.id} question={q} />)
  return (
    <Card className='mb-3 ext-card'>
      <ExtCardHeader onClick={onCollapseToggle}>{scenario.title}
        {!isOpen && <FontAwesomeIcon icon={faChevronDown} fixedWidth className='ml-auto' />}
      </ExtCardHeader>
      <Collapse isOpen={isOpen} className='collapse-show-print'>
        <CardBody>
          {questionParts}
        </CardBody>
      </Collapse>
    </Card>

  )
}

const QuestionAnswers = ({ questionsAndAnswers }) => {
  const [scenarios, setScenarios] = useState([])
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (questionsAndAnswers.scenarios) {
      setScenarios(questionsAndAnswers.scenarios)
    }
  }, [questionsAndAnswers])

  const cards = scenarios.map(s => <Scenario key={s.id} scenario={s} />)

  return (
    <div>
      <h3 className='mb-4'>{t('YOUR_SUBMITTED_SURVEY_TITLE')}</h3>
      <p>{t('YOUR_SUBMITTED_SURVEY_BODY')}</p>
      {cards}
      <p className='text-center'>
        <i>{t('SAVE_AS_PDF_BY_PRINTING')}</i>
      </p>
    </div>
  )
}

export default QuestionAnswers
