import React, { useEffect, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import classNames from 'classnames'
import { InputGroup, InputGroupAddon, InputGroupText, UncontrolledPopover, PopoverBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'react-i18next'

const InfoPopOver = ({ target }) => {
  const { t, i18n } = useTranslation()
  return (
    <UncontrolledPopover placement='bottom' target={target} trigger='click hover'>
      <PopoverBody>{t('START_TYPING_TO_SEARCH')}</PopoverBody>
    </UncontrolledPopover>
  )
}
const AutoComplete = ({ placeholder, defaultValue, defaultSearchValue = '', dataSource = [], onSelected, onBlur, invalid }) => {
  const [suggestions, setSuggestions] = useState([])
  const [searchVal, setSearchVal] = useState('')

  useEffect(() => {
    setSearchVal(defaultSearchValue)
  }, [defaultSearchValue])

  useEffect(() => {
    if (searchVal === '') {
      onSelected({ label: '' })
    }
  }, [searchVal])

  const inputClass = classNames('form-control', invalid ? 'is-invalid' : '')

  function getSuggestionValue (suggestion) {
    return `${suggestion.description}`
  }

  function renderSuggestion (suggestion) {
    return (<span>{suggestion.description}</span>)
  }

  function onSuggestionsFetchRequested ({ value }) {
    setSuggestions(dataSource.filter(option => option.description.toLowerCase().startsWith(value.toLowerCase())))
  }

  function onSuggestionSelected (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
    setSearchVal(suggestion.description)
    onSelected(suggestion)
  }

  return (
    <div>
      <InputGroup>
        <InputGroupAddon addonType='prepend' id='name_xyz' >
          <InputGroupText><FontAwesomeIcon icon={faSearch} fixedWidth /></InputGroupText>
        </InputGroupAddon>
        <Autosuggest
          shouldRenderSuggestions={() => true}
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={() => setSuggestions([])}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={onSuggestionSelected}
          inputProps={{
            onBlur: onBlur,
            placeholder: placeholder,
            className: inputClass,
            value: searchVal,
            onKeyDown: e => e.key === 'Enter' ? e.preventDefault() : true,
            onChange: (event, { newValue }) => {
              setSearchVal(newValue)
            }
          }}
        />
      </InputGroup>
      <InfoPopOver target='name_xyz'/>
    </div>
  )
}

export default AutoComplete
